import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { Alert } from '@mui/material';
import { chartColors } from './colours';
import { startOfMonth, format } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const MonthlyTourPax = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    const [currentDate, setCurrentDate] = useState(new Date());
    const chartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const functionUrl = `https://us-central1-curacao-tours.cloudfunctions.net/getSheetData?range=Bookings_2024!A2:G`;
            try {
                const response = await fetch(functionUrl);
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();

                const islandRouteBookings = data.filter(row => row[2] === 'Island Routes');
                const tourData = {};

                islandRouteBookings.forEach(row => {
                    const date = new Date(row[6]);
                    if (date <= currentDate) {
                        const tourTitle = row[3];
                        const pax = parseInt(row[5]) || 0;
                        const monthKey = format(startOfMonth(date), 'yyyy-MM-01');

                        if (!tourData[tourTitle]) {
                            tourData[tourTitle] = {};
                        }
                        if (!tourData[tourTitle][monthKey]) {
                            tourData[tourTitle][monthKey] = 0;
                        }
                        tourData[tourTitle][monthKey] += pax;
                    }
                });

                const colorKeys = Object.keys(chartColors);
                const datasets = Object.entries(tourData).map(([tour, months], index) => ({
                    label: tour,
                    data: Object.entries(months).map(([month, pax]) => ({ x: month, y: pax })),
                    borderColor: chartColors[colorKeys[index % colorKeys.length]],
                    backgroundColor: chartColors[colorKeys[index % colorKeys.length]],
                    fill: false,
                }));

                const allMonths = [...new Set(datasets.flatMap(dataset => dataset.data.map(point => point.x)))].sort();

                setChartData({
                    labels: allMonths,
                    datasets,
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        // Cleanup function to destroy the chart
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [currentDate]);

    return (
        <div>
            <h2>Monthly Island Route Tour Participants (2024)</h2>
            <Alert severity="info" style={{ marginBottom: '1rem' }}>
                This chart displays monthly aggregated participant data for Island Route bookings in 2024, up to {format(currentDate, 'MMMM yyyy')}.
            </Alert>
            <Line 
                ref={chartRef}
                data={chartData} 
                options={{ 
                    responsive: true, 
                    plugins: { 
                        legend: { position: 'top' },
                        tooltip: {
                            callbacks: {
                                title: (context) => format(new Date(context[0].parsed.x), 'MMMM yyyy'),
                                label: (context) => `${context.dataset.label}: ${context.parsed.y} participants`
                            }
                        }
                    },
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'month',
                                displayFormats: {
                                    month: 'MMM yyyy'
                                }
                            },
                            adapters: {
                                date: {
                                    locale: enUS,
                                },
                            },
                            title: {
                                display: true,
                                text: 'Month'
                            }
                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Number of Participants'
                            },
                            ticks: {
                                callback: (value) => Math.round(value)
                            }
                        }
                    }
                }} 
            />
        </div>
    );
};

export default MonthlyTourPax;
