import React from 'react';
import { Card, Grid, CardContent, Typography, Box, Tab, Tabs, AppBar } from '@mui/material';
import TodayToursDashboard from '../../components/dashboard/TodayToursDashboard';
import MonthlyRevenueChart from '../../components/dashboard/MonthlyRevenueChart';
import MonthlyTourRevenue from '../../components/dashboard/MonthlyTourRevenue';
import MonthlyTourPax from '../../components/dashboard/MonthlyTourPax';
import MonthlyTourPaxResmark from '../../components/dashboard/MonthlyTourPaxResmark';
import MonthlyTourPaxTreksoft from '../../components/dashboard/MonthlyTourPaxTreksoft'; // Import the new component
import MonthlyRevenueByAgent from '../../components/dashboard/MonthlyRevenueByAgent';
import MonthlyRevenueByAgentSardonyx from '../../components/dashboard/MonthlyRevenueByAgentSardonyx';
import MonthlyRevenueByAgencySardonyx from '../../components/dashboard/MonthlyRevenueByAgencySardonyx';
import MonthlyParticipantsChart from '../../components/dashboard/MonthlyParticipantsChart';
import MonthlyRevenueByCompany from '../../components/dashboard/MonthlyRevenueByCompany';
import DirectBookingsChart from '../../components/dashboard/DirectBookingsChart';
import BookingsPerDayBarChart from '../../components/dashboard/DailyBookingsChart';
import CurrentYearRevenue from '../../components/dashboard/CurrentYearRevenueTreksoft';
import CurrentMonthRevenue from '../../components/dashboard/CurrentMonthRevenueTreksoft';
import CurrentMonthSardonyxRevenue from '../../components/dashboard/CurrentMonthSardonyxRevenue';
import RevenuePerYearChartSardonyx from '../../components/dashboard/RevenuePerYearChartSardonyx';
import MonthlyParticipantsByTourChart from '../../components/dashboard/MonthlyParticipantsbyTourChart';
import NumberOfPassengersSardonyx from '../../components/dashboard/numberofpassengersSardonyx';
import NumberOfPassengersSardonyx2023 from '../../components/dashboard/numberofpassengersSardonyx2023';
import CurrentMonthSardonyxPax from '../../components/dashboard/CurrentMonthSardonyxPax';
import NumberOfPassengersSardonyxOTAlocation from '../../components/dashboard/numberofpassengersSardonyxOTAlocation'; // Import the new component
import MonthlyAirportTransfers from '../../components/dashboard/MonthlyAirportTransfers'; // Add this import

const DashboardPage = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom component="div">
        Dashboard
      </Typography>
      <AppBar position="static" sx={{ backgroundColor: '#424242' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          aria-label="dashboard tabs"
          indicatorColor="secondary"
          textColor="inherit"
          sx={{
            '.MuiTab-root': {
              color: '#fff',
              '&.Mui-selected': {
                color: '#ffeb3b',
                fontWeight: 'bold',
              },
              '&:hover': {
                color: '#cddc39',
                opacity: 1,
              },
            }
          }}
        >
          <Tab label="Tour Sales" />
          <Tab label="Sardonyx" />
          <Tab label="Curacao Tourism Board" />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (
        <Grid container spacing={2}>
          {/* Current Year and Month Revenue at the top */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#f5f5f5' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                  Current Year Revenue
                </Typography>
                <CurrentYearRevenue />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#f5f5f5' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                  Current Month Revenue
                </Typography>
                <CurrentMonthRevenue />
              </CardContent>
            </Card>
          </Grid>
          
          {/* Monthly Revenue Chart */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Revenue Chart
                </Typography>
                <MonthlyRevenueChart />
              </CardContent>
            </Card>
          </Grid>
          {/* Monthly Participants Chart */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Participants Chart
                </Typography>
                <MonthlyParticipantsChart />
              </CardContent>
            </Card>
          </Grid>
          {/* Monthly Tour Revenue */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Tour Revenue
                </Typography>
                <MonthlyTourRevenue />
              </CardContent>
            </Card>
          </Grid>
          {/* Monthly Tour Pax */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Tour Participants
                </Typography>
                <MonthlyTourPax />
              </CardContent>
            </Card>
          </Grid>
          {/* Monthly Tour Pax Resmark */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Tour Participants (Resmark)
                </Typography>
                <MonthlyTourPaxResmark />
              </CardContent>
            </Card>
          </Grid>
          
          {/* Monthly Tour Pax Treksoft */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Tour Participants (Treksoft)
                </Typography>
                <MonthlyTourPaxTreksoft />
              </CardContent>
            </Card>
          </Grid>
          
          {/* Monthly Airport Transfers */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Airport Transfers
                </Typography>
                <MonthlyAirportTransfers />
              </CardContent>
            </Card>
          </Grid>
          
          {/* Monthly Revenue by Agent */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Pax by Sales Channel
                </Typography>
                <MonthlyRevenueByAgent />
              </CardContent>
            </Card>
          </Grid>
         
          {/* Monthly Revenue by Company */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Revenue by Company
                </Typography>
                <MonthlyRevenueByCompany />
              </CardContent>
            </Card>
          </Grid>
          {/* Direct Bookings Chart */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Direct Bookings Chart
                </Typography>
                <DirectBookingsChart />
              </CardContent>
            </Card>
          </Grid>
          {/* Bookings Per Day Bar Chart */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Bookings Per Day Bar Chart
                </Typography>
                <BookingsPerDayBarChart />
              </CardContent>
            </Card>
          </Grid>
          {/* Monthly Participants by Tour Chart */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Participants by Tour
                </Typography>
                <MonthlyParticipantsByTourChart />
              </CardContent>
            </Card>
          </Grid>
          {/* Today's Tours - Moved to the bottom */}
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Today's Tours
                </Typography>
                <TodayToursDashboard />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {tabValue === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#f5f5f5' }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                      Current Month Sardonyx Revenue
                    </Typography>
                    <CurrentMonthSardonyxRevenue />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                      Current Month Sardonyx Pax
                    </Typography>
                    <CurrentMonthSardonyxPax />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                  Number of Passengers with Transfers (Sardonyx)
                </Typography>
                <NumberOfPassengersSardonyx />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                  Number of Passengers with Transfers (Sardonyx OTA Location)
                </Typography>
                <NumberOfPassengersSardonyxOTAlocation />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Revenue by Agent Sardonyx
                </Typography>
                <MonthlyRevenueByAgentSardonyx />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Revenue Per Year Chart Sardonyx
                </Typography>
                <RevenuePerYearChartSardonyx />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                  Monthly Revenue by Agency Sardonyx
                </Typography>
                <MonthlyRevenueByAgencySardonyx />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#ffffff' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
                  Number of Passengers with Transfers (Sardonyx2023)
                </Typography>
                <NumberOfPassengersSardonyx2023 />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {tabValue === 2 && (
        <Grid container spacing={2}>
          {/* CBT Data components */}
          <Grid item xs={12}>
            <Card sx={{ padding: '20px', boxShadow: 3, backgroundColor: '#f5f5f5' }}>
              <CardContent>
                <iframe
                  title="CBT Data"
                  width="100%"
                  height="1000px"
                  src="https://app.powerbi.com/view?r=eyJrIjoiOTlhYzE0MTYtMDI4Ni00NGM2LWFiMzUtNDYzYjc0M2UwNGQ4IiwidCI6Ijg2ZTY2MDY5LTcyMDEtNGNhOS05YWMwLWE3YjIyNDNkMzg2ZiJ9"
                  frameBorder="0"
                  allowFullScreen={true}
                ></iframe>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DashboardPage;
